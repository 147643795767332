@import url(https://fonts.googleapis.com/css?family=Nunito:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
html {
	overflow: -moz-scrollbars-none !important;
	max-width: 100%;
	overflow-x: hidden;
}

body {
  font-family: 'Work Sans', sans-serif;
  background-color:  white;
	font-size: 16px;
	scrollbar-width: none !important;
  margin:0px;
  overflow-x: hidden;
	-ms-overflow-style: none;
}
body p{
  line-height: 33px;
}

/*----------------------------NAV BAR-----------------------------*/


.navbar {
  margin: 0;
  width: 100%;
  background-color: #1A1A1A;
	color: white;
  position: fixed;
  z-index: 500;
	top: 0;
	transition: top 0.3s;
	display: inline-block;
	list-style: none;
	padding-right: 1em;
	box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.5);
	height: 6rem;
}

.navbar-left img{
  width: 250px;
  height: 200px;

  width: 130px;
  height: 90px;
	padding: 0.3rem 0rem 0rem 1rem;
}
.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.navbar-right{
	font-family: 'Poppins', sans-serif;
}
.fa-bars {
	color: white;

}

.menu-icon {
  display: none;
}

@media screen and (min-width: 960px) {

  .nav-links {
  	color: white;
    display: grid;
    text-decoration: none;
    padding: 0rem 0.7rem;

  }

  .nav-links:hover {
    border-bottom: none;
    color: #afdeb7;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease-out;
  }


  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }


}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }

  .nav-links {
    display:flex;
    background-color: #1A1A1A;
    font-size: 1.2rem;
    font-weight: lighter;
    color: white;
    text-decoration: none;
    padding: 1.5rem;
    justify-content: center;
  }

  .nav-links:hover {
    color: #B8DDFF;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 50%);
            transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: white;
    font-size: 2rem;
    font-weight: 100;

  }
}

@media screen and (max-width: 500px) {

	    .nav-links {
	      font-size: 16px;
	    }
}

/*--------------------------LANDING PAGE--------------------------*/


.landing {
	height:45rem;
	width: 100%;
  background-image: url(../../static/media/CN-36.67c1a972.png);
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	justify-content: center;
	background-attachment: fixed;
  color: black;
  
}

.landing h2 {
  font-size: 180px;
  padding: 5rem 3rem 5rem 3rem;
  font-weight: 500;
  margin: 0;
  font-family: 'Bebas Neue', sans-serif;
}

.landing p{
  font-size: 20px;
  font-weight: 400;
  padding-left: 3rem;
  padding-right:6rem;
}

.button1 {
	cursor:pointer;
	border:none;
  margin: 2rem 50px;
	color:#FFF;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: #1A1A1A;
  color: white;
  border: 2px solid #1A1A1A;
  border-radius: 1px;
}

.button1:hover {
	background:transparent;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

@media(min-width:768px) {

  .info{
    width:55rem;
   max-width:55rem;
  }
  .landing{
    padding-top: 90px;
    min-height: 90vh;

  }

}

@media only screen and (max-width: 768px) {
  .landing {
    min-height: 70vh;
    background-size: 100rem;
    width: auto;
    height: 50rem;
  }
	.landing h2{
		font-size: 120px;
    padding: 10rem 0rem 10rem 3rem;
	}
  .landing p{
    font-size: calc(10px + (50 - 35) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 400;
  	padding: 0rem 2rem 0rem 3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}

@media only screen and (max-width: 500px) {
  .landing {
    max-height: 85vh;
    min-height: 75vh;
    width: auto;

  }
  .landing h2 {
		font-size: 80px;
    padding: 10rem 0rem 5rem 2rem;
    font-weight: 500;
    margin: 0;
    word-wrap: break-word
  }

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 3rem;
    margin:0;
    line-height: 1.3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}


@media only screen and (max-width: 300px) {
  .landing {
    max-height: 65vh;
    min-height: 55vh;
    width: auto;
    
  }
  .landing h2 {
  	font-size: 26px;
    padding: 3rem 3rem 5rem 2rem;
    font-weight: 500;
    margin: 0;
    word-wrap: break-word
  }
  .landing p{
    font-size: 11px;
  	font-weight: 200;
  	padding: 0rem 2rem 0rem 2rem;
    margin:0;
    line-height: 18px;
  }

  .info button{
    padding-left: 0rem;
  }

  .button1 {
  	font-size:10px;
    border:none;
    margin: 2rem 30px;
    color:#FFF;
    justify-content:center;
    padding:0.8rem 0rem 0.8rem 0rem;
    width: 13rem;
    border: 2px solid #1A1A1A;
    border-radius: 1px;
  }
}


/*----------------------------------------------------------------*/

/*----------------------------ABOUT US----------------------------*/

.about-CN {
  display: inline-flex;
	flex-direction: column;
	min-height: 65vh;
  background-color: #1A1A1A;
  color: white;
  align-content: flex-start;
  text-align: left;
  margin:0;
  padding:0;
  padding-bottom: 100px;
}

.about-CN h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 2rem 5rem;
  margin-bottom: 0;
}

.about-CN p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 4rem 0rem 5rem;
  font-size: 20px;
  line-height: 33px;

}

.about-split{
  display:flex;
  resize: none;
}
.about-image{
  height: 30rem;
  width: 20rem;
  padding: 8rem 6rem 0rem 1rem;
  margin:0;
}

.button2 {
	cursor:pointer;
	border:none;
  margin: 2rem 5rem;
	color:#FFF;
	padding:0.8rem 4rem 0.8rem 4rem;
	font-size:15px;
  background-color: #1A1A1A;
  color: white;
  border: 2px solid white;
}

.button2:hover {
	background:white;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

@media(max-width:858px) {

  .about-split{
    display:block;
    resize: none;
  }
  .about-CN p {
    padding: 1rem 4rem 0rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
  }
  .about-image{
    height: 25rem;
    width: 16rem;
    padding: 1rem 5rem 0rem 5rem;
    align-items: center;
  }
  .button2{
    font-size: 12px;
  }

}
@media only screen and (max-width: 500px) {

  .about-CN{
    display:block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: inherit;
    padding-bottom: 3rem;
  }

  .about-split{
    display:block;
    text-align: center;
  }

  .about-CN h4 {
  	padding: 3rem 2rem 0.5rem 2rem;
    margin:0;
    font-size: 30px;
    font-weight: 100;
  }
  .about-CN p {
    padding: 1rem 2rem 0rem 2rem;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    font-weight: 100;
  }

  .about-image {
    height: 23rem;
    width: 15rem;
    padding: 0rem 2rem 0rem 2rem;
    align-items: center;
  }

  .button2 {
      text-decoration: none;
      font-size: 12px;
      align-items: center;
      padding:0.8rem 2rem 0.8rem 2rem;
      margin: 1.5rem 0rem;
      width: 15rem;

    }
}

@media only screen and (max-width: 300px) {

    .about-CN{
      display:block;
      text-align: center;
      margin: 0;
      padding: 0;
      width: inherit;
      padding-bottom: 3rem;
    }

    .about-split{
      display:block;
      text-align: center;
    }

    .about-CN h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 26px;
      font-weight: 100;
    }
    .about-CN p {
      padding: 1rem 2rem 0rem 2rem;
      text-align: center;
      line-height: 21px;
      font-size: 11px;
      font-weight: 100;
    }

    .about-image {
      height: 20rem;
      width: 13rem;
      padding: 0rem 2rem 0rem 2rem;
      align-items: center;
    }

    .button2 {
        text-decoration: none;
        font-size: 11px;
        align-items: center;
        padding:0.8rem 2rem 0.8rem 2rem;
        margin: 1.5rem 0rem;
        width: 13rem;

      }
  }


/*---------------------------SERVICES HOME PAGE---------------------------*/

.service {
  margin: 0;
  min-height: 100vh;
  background-color: white;
}
.container{
  min-height: 65vh;
  margin-bottom: 4rem;
}

.service h4 {
  text-align: center;
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 4rem 0rem 3rem 0rem;
  resize:none;
  margin: auto;
}

.wwd-p{
  text-align: left;
  font-size: 20px;
  font-weight: 200;
  padding: 0rem 3rem 0rem 4rem;
  resize: none;
  margin: auto;
}

.item {
  text-align: center;
  border-radius: 0px;
  border:2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #afdeb7;
}

@media(min-width:980px) {
  .item{
    resize: none;
    padding: 1rem 0rem 1rem 0rem;
    display :inline-block;
    background: white;
    padding: 30px;
    width: 500px;
    height: 510px;
    margin :10px;
  }
  .container {
    display: flex;
    padding: 0rem 1.5rem 0rem 1.5rem;
    resize:none;
    text-align: center;
  }
  .service-icons{
    font-size: 55px;
    padding-top: 1rem;
  }

  .item p{
    line-height:26px;
    font-style: italic;
    font-size: 15px;
    font-weight: 300;
    padding: 0rem 0rem 1rem 0rem;
    resize: none;
  }
  .item h4{
    text-align: center;
    font-size: calc(10px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 100;
    padding: 0.5rem 0rem 0.5rem 0rem;
    resize:none;
    margin-top: 0;
    margin-bottom: 0;
  }
}


@media only screen and (max-width: 980px) {
  .service h4{
    padding-bottom: 1rem;
  }
  .item{
    padding: 1rem 1rem 2rem 3rem;
    resize: none;
    display :inline-block;
    background: white;
    padding: 30px;
    width: 300px;
    height: 350px;
    margin :10px;
  }
  .man{
    margin-top: 3rem;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 30px;
  }

  .item p{
    font-size:12px;
    line-height:26px;
    font-style: italic;
    font-weight: 300;
  }
  .service {
  	background-color: white;
  	color: black;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
  }
  .wwd-p{
    padding: 1rem 2rem 0rem 2rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 12px;
  }
}


@media only screen and (max-width: 500px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 30px;
  }
  .item{
    padding: 1rem 1rem 2rem 1rem;
    resize: none;
    display :inline-block;
    background: white;
    padding: 10px;
    width: 310px;
    height: 400px;
    margin :10px;
  }
  .man{
    margin-top: 3rem;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 30px;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-style: italic;
    font-weight: 200;
  }
  .service {
  	background-color: white;
  	color: black;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
  }
  .wwd-p{
    padding: 1rem 1.5rem 0rem 1.5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 26px;
  }
  .item{
    padding: 1rem 1rem 2rem 1rem;
    resize: none;
    display :inline-block;
    background: white;
    padding: 10px;
    width: 210px;
    height: 350px;
    margin :10px;
  }
  .man{
    margin-top: 3rem;
  }

  .container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
  .service-icons{
    font-size: 27px;
  }

  .item p{
    font-size:11px;
    line-height: 20px;
    font-style: italic;
    font-weight: 200;
  }
  .service {
  	background-color: white;
  	color: black;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
  }
  .wwd-p{
    padding: 1rem 1.5rem 0rem 1.5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 11px;
  }
}

.list p{
  text-align: left;
  text-indent: 20px;
}



/*----------------------------------------------------------------*/

/*----------------------------SPORTS------------------------------*/

.sports {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: #1A1A1A;
	color: white;
  padding-bottom: 60px;
  text-align: left;
}

.sports h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 2rem 5rem;
  margin-bottom: 0;
}

.sports p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 8rem 2rem 5rem;
  line-height: 33px;
}

.sports-split{
  display:flex;
  resize: none;
}

.whatwedo-list p{
  font-weight: 100;
  font-size: 20px;
  margin-top: 0;
  text-align: left;
  padding: 0rem 0rem 0rem 2rem;
}

.sports-split .sports-img{
  height: 500px;
  width: 355px;
  padding: 0rem 2rem 0rem 5rem;
  margin:0;
  resize: both;
}
.sports-split-left{
  display: flex;
	flex-direction: column;
}

.button3 {
	cursor:pointer;
	border:none;
  margin: 2rem 5rem;
	color:#FFF;
	padding:0.8rem 3rem 0.8rem 3rem;
	font-size:15px;
  background-color: #1A1A1A;
  color: white;
  border: 2px solid white;
  border-radius: 1px;
}

.button3:hover {
	background:white;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

@media(max-width:898px) {

  .sports-split .sports-img{
    height: 380px;
    width: 300px;
    margin-right:0;
    padding: 0rem 0rem 0rem 5rem;
  }

  .sports p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }

  .whatwedo-list p{
    margin-left: 0;
    text-align: left;
    padding: 0rem 0rem 0rem 0rem;
  }
  .button3{
    font-size: 12px;
  }
}
@media only screen and (max-width: 500px) {
  .sports-split{
    display:block;
  }
  .sports-split .sports-img{
    justify-content: center;
    margin: 0 auto;
    height: 23rem;
    width: 15rem;
    padding: 2rem 2rem 1rem 2rem;
    align-items: center;
  }

  .sports h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .sports p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

  .whatwedo-list p{
    padding: 0rem 2rem 0rem 5rem;
    text-align: left;
  }

  .button3 {
    justify-content: center;
    margin: 2rem 5rem;
  	color:#FFF;
  	padding:0.8rem 0rem 0.8rem 0rem;
  	font-size:12px;
    width: 15rem;
    background-color: #1A1A1A;
    color: white;
    border: 2px solid white;
    border-radius: 1px;
  }
}

@media only screen and (max-width: 300px) {
  .sports-split{
    display:block;
  }
  .sports-split .sports-img{
    justify-content: center;
    margin: 0 auto;
    height: 20rem;
    width: 13rem;
    padding: 2rem 2rem 1rem 2rem;
  }

  .sports h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 26px;
      font-weight: 200;
      text-align: center;

  }
  .sports p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 21px;
      font-size: 11px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

  .whatwedo-list p{
    padding: 0rem 2rem 0rem 3rem;
    text-align: left;
  }

  .button3 {
    justify-content: center;
    margin: 2rem 2rem;
  	color:#FFF;
  	padding:0.8rem 0rem 0.8rem 0rem;
  	font-size:10px;
    width: 13rem;
    background-color: #1A1A1A;
    color: white;
    border: 2px solid white;
    border-radius: 1px;
  }
}



/*----------------------------------------------------------------*/

/*-----------------------------CONTACT----------------------------*/

.contact {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: white;
	color: black;

}

.contact h4 {
  font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.contact p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 0rem 0rem 3rem;
}

.contact-div{
  min-height: 90vh;
  padding: 10rem 2rem 0rem 5rem;
  flex-direction: column;
  color: black;
  align-content: flex-start;
  
}
.container2{
  flex-direction: column;
  min-height: 60vh;
  color: black;
  align-content: flex-start;
  padding: 2rem 2rem 0rem 5rem;

}

.contact-split{
  display:flex;
  resize: none;
}
.contact-split-left{
  padding: 2rem 1rem 6rem 1rem;
  display: inline;
  width:50%;
}
.contact-split-left h3{
  color: #F96;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-split-left p{
	font-size:15px;
}

.contact-msg{
  padding: 0;
  margin:0;
}

.input-group2 .bottom{
  line-height: 10px;
}
.input-row2{
  color: black;
  display: flex;
  font-size: 15px;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }

#contact {
  min-height: 60vh;
  text-align: left;
  width: 100%;
	padding:25px;
  padding-top: 5rem;
  resize: none;
}

#contact h3 {
	color: #F96;
	display: block;
	font-size: 30px;
	font-weight: 400;
}
#contact p {
	margin:5px 0 15px;
	display:block;
	font-size:13px;
  color: black;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
  resize: none;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
	width:70%;
	border:1px solid #CCC;
	background-color: #F9F9F9;
	margin:0 0 5px;
	padding:10px;
  resize: none;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
  resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
  width:73%;
	border:none;
	background:#363636;
	color:#FFF;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#afdeb7;
  color: black;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}

.div-map{
  display: flex;
  padding: 0rem 4rem 4rem 0rem;
}
.map{
  width:350px;
  height:250px;
}

@media only screen and (max-width: 898px) {
  .contact-split{
    display:block;
    resize: none;
  }

  #contact {
    min-height: 60vh;
    text-align: left;
    width: 100%;
    padding: 2rem 0rem 2rem 1rem;
  }
  .contact-split-left{
    padding: 0rem 7rem 0rem 0rem;
  }
  .contact-split-left h3{
    color: #F96;
    padding: 0rem 0rem 0rem 1rem;
    font-size: 30px;
    font-weight: 400;
  }
  .contact-split-left p{
    font-size:15px;
    padding: 0rem 0rem 0rem 1rem;
  }
  .map{
    width:350px;
    height:250px;
    margin: 1rem 3rem 0rem 1rem;
  }

}
@media only screen and (max-width: 500px) {

  .container2{
    flex-direction: column;
    min-height: 60vh;
    color: black;
    align-content: flex-start;
    padding: 2rem 1rem 0rem 1rem;
  }
  .contact-div{
    flex-direction: column;
    min-height: 60vh;
    color: black;
    align-content: flex-start;
    padding: 9rem 1rem 0rem 1rem;
  }

  .contact-split{
    display:flex;
    resize: none;
  }
  .contact-split-left{
    padding: 2rem 2rem 0rem 0rem;
    display: inline;
    width:50%;
  }
  .contact-split{
    display:block;
    resize: none;
  }

  #contact {
    min-height: 60vh;
    text-align: center;
    width: 100%;
    padding: 2rem 0rem 2rem 0rem;
    margin: 0;
  }

  .contact-split-left h3{
    color: #F96;
    font-size: 30px;
    font-weight: 400;
    padding: 0rem 0rem 0rem 1rem;

  }
  .contact-split-left p{
    padding: 0rem 0rem 0rem 1rem;
  	font-size:14px;
  }
  .map{
    width:315px;
    height:200px;
    margin: 1rem 3rem 0rem 1rem;
    }

    #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    	width:85%;
    	border:1px solid #CCC;
    	background-color: #F9F9F9;
    	margin:0 0 5px;
    	padding:10px;
      resize: none;
    }

    #contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
    	transition:border-color 0.3s ease-in-out;
    	border:1px solid #AAA;
    }

    #contact textarea {
    	height:100px;
      resize:none;
    }

    #contact button[type="submit"] {
    	cursor:pointer;
      width:92%;
    	border:none;
    	background:#363636;
    	color:#FFF;
    	padding:10px;
    	font-size:15px;
    }

    #contact button[type="submit"]:hover {
    	background:#afdeb7;
      color: black;
    	transition:background-color 0.3s ease-in-out;
    }

    #contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

    #contact input:focus, #contact textarea:focus {
    	outline:0;
    	border:1px solid #999;
    }
}


@media only screen and (max-width: 300px) {


  .contact-split-left h3{
    color: #F96;
    font-size: 26px;
    font-weight: 400;
    padding: 0rem 0rem 0rem 1rem;

  }
  .contact-split-left p{
    padding: 0rem 0rem 0rem 1rem;
  	font-size:11px;
  }
  .map{
    width:210px;
    height:180px;
    margin: 1rem 3rem 0rem 1rem;
    }

    #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    	width:85%;
    	border:1px solid #CCC;
    	background-color: #F9F9F9;
    	margin:0 0 5px;
    	padding:10px;
      resize: none;
    }

    #contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
    	transition:border-color 0.3s ease-in-out;
    	border:1px solid #AAA;
    }

    #contact textarea {
    	height:100px;
      resize:none;
    }

    #contact button[type="submit"] {
    	cursor:pointer;
      width:92%;
    	border:none;
    	background:#363636;
    	color:#FFF;
    	padding:10px;
    	font-size:15px;
    }

    #contact button[type="submit"]:hover {
    	background:#afdeb7;
      color: black;
    	transition:background-color 0.3s ease-in-out;
    }

    #contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

    #contact input:focus, #contact textarea:focus {
    	outline:0;
    	border:1px solid #999;
    }
  }


/*------------------------------FOOTER----------------------------*/

.footer {
	text-align: center;
	background-color: #1A1A1A;
	color: white;
	line-height: 70px;
	height: 70px;
	text-align: center;
}

.footer-content {
	vertical-align: middle;
}

/*----------------------------------------------------------------*/

/*----------------------------SCROLL------------------------------*/

.MuiFab-secondary {
	background-color: blue !important;
	color: white !important;
}

/*----------------------------------------------------------------*/


/*----------------------------ABOUT PAGE CSS------------------------------*/

.aboutpage-CN {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  resize: none;
  padding-top: 80px;
  font-family: 'Schibsted Grotesk', sans-serif;
}
.header-image{
  text-align: left;
	display: flex;
	flex-direction: column;
	color: black;
  overflow:scroll;
}
.abt-back{
  padding: 4rem 0rem 6rem 0rem;
  text-align: center;
}

.aboutpage-CN h4 {
	font-size: 40px;
	font-weight: 300;
	padding: 0rem 3rem 0rem 3rem;
	text-align: center;

}

.aboutpage-CN p {
	font-size: 20px;
	font-weight: 100;
	padding: 0rem 7rem 0rem 7rem;
  line-height: 33px;
}

.aboutpage-split{
	flex-direction: column;
  background-color: white;
  color: white;
  margin:0;
  padding:0;
	display: flex;
}
.aboutpage-split h4 {
	font-weight: 200;
	padding: 2rem 0rem 4rem 0rem;
  font-size: 60px;
  line-height: 40px;
	color: black;
  margin-bottom: 0;
}

.abt-back2{
  background-color: #1A1A1A;
  color: white;
  padding: 0rem 2rem 5rem 2rem;
  text-align: center;
}
.abt-back2 h4{
  padding: 3rem 0rem 0.7rem 0rem;
  margin-bottom: 0;

}
.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 7rem;
  background: white;
  margin-bottom: 50px;
}
.abt-p{
  display: flex;
  resize: none;
}
.abt-p h5{
  padding: 1rem 3rem 0rem 3rem;
  font-size: 20px;
  text-align: center;
}

.abt-p p{
  padding: 1rem 3rem 1rem 3rem;
  font-size: 20px;
  text-align: center;
}


.test-container  p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 1rem 0rem 1rem;
  font-size: 20px;
  line-height: 33px;
	color: black;
	justify-content: center;
	text-align: center;
}


.test-container h2 {
	font-weight: 600;
	padding: 0rem 4rem 0rem 3rem;
  font-size: 25px;
  line-height: 33px;
	color: black;
	text-align: center;
}
.test-container h3 {
	font-weight: 300;
	padding: 0rem 0rem 1rem 0rem;
  font-size: 23px;
  line-height: 33px;
	color: black;
  text-align: center;
  

}
.test-container{
	min-height: 65vh;
	margin-bottom: 4rem;
	display: flex;
}
.test-div{
	min-width: 150px;
  max-width: 410px;
	justify-content: center;
	text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}
.color-div{
  background-color: #efefef;
  border: 2rem solid white;
}

.test-underline{
	position: relative;
	border: none;
	height: 3px;
	width: 7rem;
	background: #DBEEFF;
	margin-bottom: 3rem;
	margin-left: 5rem;
}

.pic {
  display: flex;
	padding-bottom: 1rem;
	justify-content: center;
	text-align: center;
  padding-top: 3rem;
}

.pic img {
  width: 10rem;
  height: 10rem;
}

.test-container {
  position:relative;
	justify-content: center;
	text-align: center;
}

.testimony-pic {
  display: inline-block;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #e6e6e6;
  overflow: hidden;
  z-index: 1;
}
.testimonial-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;
}

.test-button {
  padding: 0rem 0rem 0rem 2rem;
  margin-top: 0;
}

.testimonial-button{
  cursor:pointer;
	border:none;
  margin: 2rem 50px;
	color: black;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: transparent;
  color: black;
  border: 2px solid black;
  border-radius: 1px;
}


@media only screen and (max-width: 980px) {
  .test-container{
    width :100%;
    height :100%;
    justify-content: center;
    text-align: center;
  }
}



@media only screen and (min-width: 768px) {
  #about-img{
    height: 400px;
    width: 400px;
    resize: none;
  }
}
@media only screen and (max-width: 768px) {

  .abt-back p {
  	padding: 1rem 3rem 0rem 3rem;
    font-size: 18px;
  }
  .aboutpage-CN h4 {
    font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 0rem 0rem 1rem 0rem;
  	font-weight: 300;
    margin-bottom: 0;
  }
  .abt-back2 p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
  }
  .aboutpage-text p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
  }
  .abt-p{
    display:inline;
    resize: none;
    text-align: center;
  }
  .abt-back2{
  	padding: 0rem 3rem 4rem 3rem;
  }

  .aboutpage-split {
    flex-direction: column;
    min-height: 60vh;
    margin:0;
    padding:0;
    justify-content: center;
    text-align: center;
  }

 


  .test-container  p {
    font-size: 18px;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 20px;
    line-height: 33px;
    color: black;
  }

  .test-container h5 {
    font-weight: 300;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 23px;
    line-height: 33px;
    color: black;
  }
}

@media only screen and (max-width: 500px) {

    .aboutpage-CN p{
      font-size: 14px;
    }

  .aboutpage-split {
    flex-direction: column;
    min-height: 60vh;
  }
  .aboutpage-split h4 {
    font-weight: 300;
    padding: 2rem 4rem 0rem 5rem;
    font-size: 35px;
    line-height: 40px;
    color: black;
  }
  
  .test-container{
    width :100%;
    height :100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .test-container  p {
    font-size: 18px;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 20px;
    line-height: 30px;
    color: black;
  }
  
  .test-container h5 {
    font-weight: 300;
    padding: 0rem 0rem 2rem 0rem;
    font-size: 25px;
    line-height: 0px;
    color: black;
    justify-content: left;
    text-align: left;
  }
  
}


@media only screen and (max-width: 300px) {

      .aboutpage-CN p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
      }
      .aboutpage-CN h4{
        font-size: 26px;
      }

      .abt-back p {
      	padding: 1rem 2rem 0rem 2rem;
        font-size: 11px;
        margin: 0;

      }
      .abt-p h5{
        justify-content: center;
        padding: 0;

      }

    }


/*----------------------------CLIENT PAGE CSS------------------------------*/



.Client {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.Client h4 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.Client p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

.list-view {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,1);
    z-index: 1;
}


/*----------------------------CLIENT PAGE CSS------------------------------*/

.Service-CN {
	display: flex;
	flex-direction: column;
  align-content: flex-start;
  text-align: center;
  padding: 7rem 5rem 3rem 5rem;
}
.Service-CN h4 {
	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
  padding: 1rem 0rem 1rem 0rem;
	font-weight: 300;
  background-color: white;
  color:black;
  margin-bottom: 0;
}

.Service-CN p {
	font-size: calc(8px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 80;
  font-style: italic;
  padding: 1rem 5rem 1rem 5rem;
}
.service-split-img{
  height: 350px;
  width: 400px;
  padding: 0rem 2rem 0rem 2rem;
}

.service-split{
  display:flex;
  color: black;
  resize: none;
  padding: 1rem 0rem 0rem 0rem;
  margin-right: 0;
}

.section1-CN {
  display: flex;
	flex-direction: column;
  background-color: white;
  color: black;
  align-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
  margin-top: 0;
}
.section1-CN h4 {
  font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin-top: 0;
}
.section1-CN p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

.section2-CN {
	display: flex;
	flex-direction: column;
  background-color: white;
  color: black;
  align-content: flex-start;
  padding: 1rem 0rem 2rem 0rem;
  margin-top: 0;
}

.section2-CN h4 {
  font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin-top: 0;
}
.section2-CN p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}
.section3-CN {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
  padding: 1rem 0rem 3rem 0rem;
}
.section3-CN h4 {
  font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin-top: 0;
}
.section3-CN p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}

@media only screen and (max-width: 828px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: calc(8px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
  	font-weight: 80;
    font-style: italic;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 410px;
    width: 310px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 500px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 14px;
  	font-weight: 80px;
    font-style: italic;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 14px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 300px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 300px) {
  .Service-CN{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Service-CN h4 {
  	font-size: 26px;
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Service-CN p {
  	font-size: 11px;
  	font-weight: 20px;
    font-style: italic;
    line-height: 20px;
    padding: 1rem 0rem 0rem 0rem;
  }
  .service-split{
    display:block;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0;

  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 11px;
    line-height: 20px;
    padding: 0rem 2rem 0rem 2rem;
  }
  .service-split img{
    height: 300px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: 0;
  }
}
/*----------------------------FAQ PAGE CSS------------------------------*/

.FAQ-CN {
	display: flex;
	flex-direction: column;
	min-height: 60vh;
	background-color: white;
	color: black;
  align-content: flex-start;
}

.FAQ-CN h3 {
	font-size: calc(20px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN h4 {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.FAQ-CN p {
	font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}



/*----------------------------FAQ PAGE CSS------------------------------*/


.faq-content {
  margin: 0;
  padding-left: 3em;
  padding-top:3rem;
  color: black;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px dotted #ccc;
  color: black;
}

.panel-title {
  font-size: 21px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 68px;
  display: block;
  cursor: pointer;
  color: black;
}

.panel-content {
  font-size: 20px;
  padding: 0px 14px 0px 0px;
  height: 0;
  color: black;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: .4s ease;
}

.panel:checked ~ .panel-content{
  height: auto;
  opacity: 1;
  padding: 14px;
  color: black;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: .2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

/*---------------------- Scroll behavior ---------------------*/


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.reveal {
  position: relative;
  opacity: 0;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.reveal.active {
  opacity: 1;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.active.fade-bottom {
  -webkit-animation: fade-bottom 0.8s  ease-in;
          animation: fade-bottom 0.8s  ease-in;
}
.active.fade-left {
  -webkit-animation: fade-left 0.6s ease-in;
          animation: fade-left 0.6s ease-in;
}
.active.fade-right {
  -webkit-animation: fade-right 0.6s ease-in;
          animation: fade-right 0.6s ease-in;
}
@-webkit-keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@keyframes fade-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;

  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;

  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/*----------------------------*/

.thankyou{
  	text-align: center;
  	display: flex;
  	flex-direction: column;
    background-image: url(../../static/media/CN-36.67c1a972.png);
    padding-top: 30px;
    color: black;
    background-size: 100rem;
    margin-top: 80px;

}

.thankyou h4{
  padding: 10rem;
  text-align: center;
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .thankyou{
    	text-align: center;
    	display: flex;
    	flex-direction: column;
      padding-top: 80px;
      color: black;
      background-size: 100rem;
      margin-top: 80px;
      height: 40rem;
  }
  .thankyou h4{
    padding: 5rem;
    text-align: center;
    font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
  }


}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 7px;
  outline: none;
  border: none;
  cursor: none;
}

.btn--primary {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background-color: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

